import React from "react";
import styles from "./Quotes.module.scss";
import { StaticQuery, graphql } from "gatsby";
import Quotes from "./Quotes";

const MUSICIANS_QUOTE_QUERY = graphql`
  query MusiciansQuoteQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "musicianReviews" } } }
    ) {
      nodes {
        frontmatter {
          name
          quote
          visibleOnFrontpage
          cloudinary_image {
            image
            alt
          }
        }
      }
    }
  }
`;

export default function MusicianQuotes() {
  return (
    <StaticQuery
      query={MUSICIANS_QUOTE_QUERY}
      render={data => (
        <div className={styles.quotesParent}>
          <h2>Tilbakemeldinger fra musikere</h2>
          <Quotes reviews={data.allMarkdownRemark.nodes} restricted={true} />
        </div>
      )}
    />
  );
}

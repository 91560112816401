import React from "react";
import styles from "./About.module.scss";
import { StaticQuery, graphql } from "gatsby";
import Content, { HTMLContent } from "../Content";
import Image from "../Image";
import Fade from "react-reveal/Fade";
import { FaEnvelope, FaPhone } from "react-icons/fa";

const ABOUT_QUERY = graphql`
  query AboutQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "about" } }) {
      id
      html
      frontmatter {
        title
        cloudinary_image {
          alt
          image
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "team-member" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            title
            mobil
            email
            cloudinary_image {
              image
            }
          }
        }
      }
    }
  }
`;

export default function About() {
  return (
    <StaticQuery
      query={ABOUT_QUERY}
      render={data => {
        const { markdownRemark: section } = data;
        return (
          <AboutTemplate
            content={section.html}
            contentComponent={HTMLContent}
            title={section.frontmatter.title}
            team={data.allMarkdownRemark}
            cloudinaryImage={section.frontmatter.cloudinary_image}
          />
        );
      }}
    />
  );
}

export function AboutTemplate({
  content,
  contentComponent,
  title,
  team,
  photo,
  cloudinaryImage
}) {
  const PageContent = contentComponent || Content;

  return (
    <section id="om" className={styles.section}>
      <div className={styles.container}>
        <Fade>
          <h2>{title}</h2>
        </Fade>
        <div className={styles.contentGrid}>
          <div className={styles.imageContainer}>
            <Fade>
              <Image
                className={styles.mainImage}
                cloudinaryImage={cloudinaryImage}
                imgStyle={{
                  objectFit: "contain"
                }}
              />
            </Fade>
          </div>
          <div className={styles.contentContainer}>
            <Fade>
              <PageContent content={content} />
            </Fade>
          </div>
        </div>
        {!!team ? (
          <>
            <Fade bottom>
              <h3>Brillante-teamet</h3>
            </Fade>
            <div className={styles.teamContainer}>
              <div className={styles.teamGrid}>
                {team.edges.map(member => (
                  <div className={styles.teamMember} key={member.node.id}>
                    <Fade>
                      <div className={styles.portrait}>
                        <Image
                          cloudinaryImage={
                            member.node.frontmatter.cloudinary_image
                          }
                        />
                      </div>{" "}
                    </Fade>
                    <Fade up>
                      <div className={styles.name}>
                        {member.node.frontmatter.name}
                      </div>
                      <div className={styles.title}>
                        {member.node.frontmatter.title}
                      </div>
                      <div className={styles.email}>
                        <a href={`mailto:${member.node.frontmatter.email}`}>
                          <FaEnvelope /> <span>Send epost</span>
                        </a>
                      </div>
                      <div className={styles.mobil}>
                        <FaPhone /> {member.node.frontmatter.mobil}
                      </div>
                    </Fade>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </section>
  );
}

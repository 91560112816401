import React from "react";
import { FaMusic, FaThumbsUp, FaTasks } from "react-icons/fa";
import { IconContext } from "react-icons";
import Fade from "react-reveal/Fade";
import { StaticQuery, graphql } from "gatsby";
import Image from "../Image";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Content, { HTMLContent } from "../Content";

import styles from "./Events.module.scss";

import Button from "../Button";

const EVENTS_QUERY = graphql`
  query EventsQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "events" } }) {
      id
      html
      frontmatter {
        title
        cloudinary_image {
          alt
          image
        }
      }
    }
  }
`;

export default function EventWithQuery() {
  return (
    <StaticQuery
      query={EVENTS_QUERY}
      render={data => {
        const { markdownRemark: section } = data;
        return (
          <EventsTemplate
            content={section.html}
            contentComponent={HTMLContent}
            title={section.frontmatter.title}
            cloudinaryImage={section.frontmatter.cloudinary_image}
          />
        );
      }}
    />
  );
}

export function EventsTemplate({
  content,
  contentComponent,
  title,
  mainImage,
  cloudinaryImage
}) {
  const PageContent = contentComponent || Content;

  return (
    <section className={styles.section} id="events">
      <div className={styles.container}>
        <Fade>
          <h2>{title}</h2>
        </Fade>
        <div className={styles.pitchContainer}>
          {" "}
          <div className={styles.imageContainer}>
            <Fade>
              <Image
                imgStyle={{
                  objectFit: "cover"
                }}
                className={styles.mainImage}
                imageInfo={mainImage}
                cloudinaryImage={cloudinaryImage}
              />
            </Fade>
          </div>
          <div className={styles.pitch}>
            <Fade>
              <PageContent content={content} />
            </Fade>
            <div className={styles.buttonContainer}>
              <Fade bottom>
                <Button
                  width="15rem"
                  className={styles.callToAction}
                  component={AnchorLink}
                  href="#kontakt"
                >
                  Send en henvendelse
                </Button>
              </Fade>
            </div>
          </div>
        </div>
        <div className={styles.iconListContainer}>
          <Fade>
            <h3 className={styles.subtitle}>Ved å bruke Brillante får du:</h3>
          </Fade>
          <IconContext.Provider
            value={{
              color: "white",
              size: "3em",
              className: "global-class-name"
            }}
          >
            <div className={styles.iconList}>
              <div className={styles.listItem} id={styles.icon1}>
                <Fade>
                  <div className={styles.iconContainer}>
                    <FaMusic />
                  </div>{" "}
                </Fade>
                <Fade>
                  <div className={styles.iconText}>
                    Stort utvalg av profesjonelle musikere
                  </div>
                </Fade>
              </div>

              <div className={styles.listItem} id={styles.icon2}>
                <Fade delay={250}>
                  <div className={styles.iconContainer}>
                    <FaThumbsUp />
                  </div>{" "}
                </Fade>
                <Fade delay={250}>
                  <div className={styles.iconText}>Kvalitetsgaranti</div>
                </Fade>
              </div>
              <div className={styles.listItem} id={styles.icon3}>
                <Fade delay={500}>
                  <div className={styles.iconContainer}>
                    <FaTasks />
                  </div>
                </Fade>
                <Fade delay={500}>
                  <div className={styles.iconText}>
                    Tett oppfølging og sikre avtaler
                  </div>
                </Fade>
              </div>
            </div>
          </IconContext.Provider>
        </div>
      </div>
    </section>
  );
}

import React from "react";
import styles from "./Musicians.module.scss";
import { StaticQuery, graphql } from "gatsby";
import Image from "../Image";
import Fade from "react-reveal/Fade";

import Content, { HTMLContent } from "../Content";
import Button from "../Button";

const MUSICIANS_QUERY = graphql`
  query MusiciansQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "musicians" } }) {
      html
      frontmatter {
        title
        cloudinary_image {
          alt
          image
        }
      }
    }
  }
`;

export default function Musicians() {
  return (
    <StaticQuery
      query={MUSICIANS_QUERY}
      render={data => {
        const { markdownRemark: section } = data;
        return (
          <MusiciansTemplate
            content={section.html}
            contentComponent={HTMLContent}
            title={section.frontmatter.title}
            cloudinaryImage={section.frontmatter.cloudinary_image}
          />
        );
      }}
    />
  );
}

export function MusiciansTemplate({
  content,
  contentComponent,
  title,
  mainImage,
  reviews,
  cloudinaryImage
}) {
  const PageContent = contentComponent || Content;
  return (
    <section id="musikere" className={styles.section}>
      <div className={styles.container}>
        <Fade>
          <h2>{title}</h2>
        </Fade>
        <div className={styles.contentGrid}>
          <div className={styles.pageContent}>
            <Fade>
              <PageContent content={content} />
            </Fade>
            <div className={styles.buttonContainer}>
              <Fade>
                <Button
                  component="a"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeOZEL6ssNLZpwi4425AHtlZqT6FFaSIy2RFbbyQIGoOtedZA/viewform?usp=sf_link"
                  target="_blank"
                >
                  Registrer deg
                </Button>
              </Fade>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <Fade>
              <Image
                className={styles.mainImage}
                style={{
                  objectPosition: "0 0",
                  maxHeight: "25rem",
                  overflow: "hidden"
                }}
                imgStyle={{
                  objectFit: "cover"
                }}
                cloudinaryImage={cloudinaryImage}
              />
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout/Layout";
import GenericHelmet from "../components/Helmets/GenericHelmet";
import BlogCardContainer from "../components/Sections/BlogCardContainer";
import Header from "../components/Layout/Header";
import Events from "../components/Sections/Events";
import Musicians from "../components/Sections/Musicians";
import About from "../components/Sections/About";
import Contact from "../components/Sections/Contact";

import MusicianQuotes from "../components/Sections/MusicianQuotes";
import CustomerQuotes from "../components/Sections/CustomerQuotes";

class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const { location } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <>
        <GenericHelmet location={location} />
        <Header />
        <Layout location={location}>
          <Events />
          <CustomerQuotes />
          <Musicians />
          <MusicianQuotes />
          <About />
          <BlogCardContainer posts={posts} />
          <Contact />
        </Layout>
      </>
    );
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      limit: 4
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            slug
            uri
          }
          frontmatter {
            title
            templateKey
            date(formatString: "DD. MMMM YYYY", locale: "nb_NO")
            cloudinary_image {
              image
              alt
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;

import React from "react";
import { Link } from "gatsby";
/* import Fade from "react-reveal/Fade"; */

import styles from "./BlogCardContainer.module.scss";

import Button from "../Button";
import BlogCard from "../Blog/BlogCard";

const Fade = ({ children }) => <div>{children}</div>;

export default function BlogCardContainer({ posts }) {
  return (
    <section id="blogg" className={styles.section}>
      <div className={styles.container}>
        <div>
          <Fade>
            <h2>Brillantebloggen</h2>
          </Fade>
          <p />
        </div>
        <div className={styles.gridWrapper}>
          <div className={styles.blogCardGrid}>
            {posts.map(({ node: post }) => (
              <Fade key={post.id}>
                <BlogCard post={post} />
              </Fade>
            ))}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Fade bottom>
            <Button component={Link} to="blogg" width="14rem">
              Se alle innleggene
            </Button>
          </Fade>
        </div>
      </div>
    </section>
  );
}

import React, { Component } from "react";
import styles from "./Contact.module.scss";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Content, { HTMLContent } from "../Content";
import Button from "../Button";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Fade from "react-reveal/Fade";
import Recaptcha from "react-recaptcha";
import { encode } from "../Helpers";

const CONTACT_QUERY = graphql`
  query ContactQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "contact" } }) {
      id
      html
      frontmatter {
        title
      }
    }
    file(relativePath: { eq: "fiolin/fiolin14.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export default class Contact extends Component {
  state = {
    formSent: false
  };

  handleFormSent = () => {
    this.setState({ formSent: true });
  };

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  render() {
    return (
      <StaticQuery
        query={CONTACT_QUERY}
        render={data => {
          const { markdownRemark: section } = data;
          return (
            <ContactTemplate
              content={section.html}
              contentComponent={HTMLContent}
              title={section.frontmatter.title}
              bgImage={data.file.childImageSharp.fluid}
              handleFormSent={this.handleFormSent}
              formSent={this.state.formSent}
            />
          );
        }}
      />
    );
  }
}

const validationScema = Yup.object().shape({
  name: Yup.string().required("Påkrevd felt"),
  phone: Yup.string().required("Påkrevd felt"),
  email: Yup.string()
    .email("Fyll inn en gyldig epost-adresse")
    .required("Påkrevd felt"),
  message: Yup.string().required("Påkrevd felt"),
  recaptcha: Yup.string().required()
});

const initialValues = {
  name: "",
  phone: "",
  email: "",
  message: "",
  recaptcha: ""
};

export function ContactTemplate({
  content,
  contentComponent,
  title,
  bgImage,
  handleFormSent,
  formSent
}) {
  const PageContent = contentComponent || Content;

  return (
    <>
      <section id="kontakt" className={styles.section}>
        {bgImage ? (
          <div className={styles.backgroundContainer}>
            <Img
              fluid={bgImage}
              style={{ opacity: 0.1, height: "100%", width: "100%" }}
              imgStyle={{ objectFit: "cover" }}
            />
          </div>
        ) : null}
        <div className={styles.container}>
          <Fade>
            <h2>{title}</h2>
          </Fade>

          {!formSent ? (
            <div className={styles.formContainer}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationScema}
                onSubmit={values => {
                  fetch("/", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded"
                    },
                    body: encode({ "form-name": "kontaktskjema", ...values })
                  })
                    .then(() => handleFormSent())
                    .catch(error => alert(error));
                }}
                render={({ errors, touched, handleSubmit, setFieldValue }) => (
                  <form
                    className={styles.form}
                    name="kontaktskjema"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={handleSubmit}
                  >
                    <Fade>
                      <div style={{ textAlign: "left", marginBottom: "3rem" }}>
                        <PageContent content={content} />
                      </div>
                    </Fade>
                    <input type="hidden" name="bot-field" />
                    <Fade>
                      <label htmlFor="name">
                        Navn
                        <Field
                          className={
                            !errors.name && touched.name
                              ? styles.valid
                              : errors.name && touched.name
                              ? styles.invalid
                              : null
                          }
                          type="text"
                          name="name"
                          placeholder="Ditt navn"
                        />
                      </label>

                      <ErrorMessage
                        className={styles.error}
                        name="name"
                        component="div"
                      />
                      <label htmlFor="phone">
                        Mobil
                        <Field
                          className={
                            !errors.phone && touched.phone
                              ? styles.valid
                              : errors.phone && touched.phone
                              ? styles.invalid
                              : null
                          }
                          type="text"
                          name="phone"
                          placeholder="Mobilnummer"
                        />
                      </label>
                      <ErrorMessage
                        className={styles.error}
                        name="phone"
                        component="div"
                      />
                      <label htmlFor="Epost">
                        Epost
                        <Field
                          className={
                            !errors.email && touched.email
                              ? styles.valid
                              : errors.email && touched.email
                              ? styles.invalid
                              : null
                          }
                          type="email"
                          name="email"
                          placeholder="Din epost-adresse"
                        />
                      </label>

                      <ErrorMessage
                        className={styles.error}
                        name="email"
                        component="div"
                      />
                      <label htmlFor="message">
                        Beskjed
                        <Field
                          className={
                            !errors.message && touched.message
                              ? styles.valid
                              : errors.message && touched.message
                              ? styles.invalid
                              : null
                          }
                          component="textarea"
                          name="message"
                          placeholder="Din beskjed"
                          rows="6"
                        />
                      </label>
                      <ErrorMessage
                        className={styles.error}
                        name="message"
                        component="div"
                      />
                      <div
                        style={{
                          display: touched.message ? "flex" : "none",
                          justifyContent: "center",
                          marginTop: "1rem"
                        }}
                      >
                        <Recaptcha
                          sitekey="6LfwOIsUAAAAAJHJ-Fj7U-yG3J13R7SbH9FYAs4k"
                          render="explicit"
                          theme="light"
                          verifyCallback={response => {
                            setFieldValue("recaptcha", response);
                          }}
                          onloadCallback={() => {
                            console.log("done loading!");
                          }}
                          hl="no"
                        />
                      </div>
                      <Fade bottom>
                        <Button
                          className={styles.submitButton}
                          type="submit"
                          width="14rem"
                        >
                          Send henvendelse
                        </Button>
                      </Fade>
                    </Fade>
                  </form>
                )}
              />
            </div>
          ) : (
            <div className={styles.formContainer}>
              <div className={styles.form}>
                <p className={styles.success}>
                  Tusen takk for din henvendelse. Vi tar kontakt med deg innen
                  kort tid.
                </p>
                <p className={styles.success}>
                  Med vennlig hilsen
                  <br />
                  <strong>Brillante musikerformidlig</strong>
                </p>
              </div>
            </div>
          )}
          <div
            style={{
              paddingBottom: "6rem"
            }}
          />
        </div>
      </section>
    </>
  );
}
